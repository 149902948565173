var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout md-gutter px-10"},[_c('div',{staticClass:"md-layout-item"},[_c('div',{staticClass:"modal-item text-center rounded-lg p-4 pointer my-1 h-32",staticStyle:{"background":"#ff4141"},on:{"click":function($event){return _vm.onShowDialog({
          title: 'Class Schedule',
          content: 'classSchedules',
          bgColor: '#ff4141'
        })}}},[_c('md-icon',{staticClass:"text-white md-size-2x"},[_vm._v("admin_panel_settings")]),_c('p',{staticClass:"text-white text-uppercase mt-3 text-base font-bold"},[_vm._v("Class Schedule")])],1)]),_c('div',{staticClass:"md-layout-item"},[_c('div',{staticClass:"modal-item text-center rounded-lg p-4 pointer my-1 h-32",staticStyle:{"background":"#eb5f00"},on:{"click":function($event){return _vm.onShowDialog({
          title: 'Special Class',
          content: 'specialClasses',
          bgColor: '#eb5f00'
        })}}},[_c('md-icon',{staticClass:"text-white md-size-2x"},[_vm._v("admin_panel_settings")]),_c('p',{staticClass:"text-white text-uppercase mt-3 text-base font-bold"},[_vm._v("Special Class")])],1)]),_c('div',{staticClass:"md-layout-item"},[_c('div',{staticClass:"modal-item text-center rounded-lg p-4 pointer my-1 h-32",staticStyle:{"background":"#37ab00"},on:{"click":function($event){return _vm.onShowMakeAnAppointment()}}},[_c('md-icon',{staticClass:"text-white md-size-2x"},[_vm._v("admin_panel_settings")]),_c('p',{staticClass:"text-white text-uppercase mt-3 text-base font-bold"},[_vm._v("Make an appointment")])],1)]),_c('div',{staticClass:"md-layout-item"},[_c('div',{staticClass:"modal-item text-center rounded-lg p-4 pointer my-1 h-32",staticStyle:{"background":"#bf01bd"},on:{"click":function($event){return _vm.onShowDialog({
          title: 'Seminar Schedule',
          content: 'workshops',
          bgColor: '#bf01bd'
        })}}},[_c('md-icon',{staticClass:"text-white md-size-2x"},[_vm._v("admin_panel_settings")]),_c('p',{staticClass:"text-white text-uppercase mt-3 text-base font-bold"},[_vm._v("Workshop")])],1)]),_c('div',{staticClass:"md-layout-item"},[_c('div',{staticClass:"modal-item text-center rounded-lg p-4 pointer my-1 h-32",staticStyle:{"background":"#0093ad"},on:{"click":function($event){return _vm.onShowDialog({
          title: 'Live Class',
          content: 'liveClasses',
          bgColor: '#0093ad'
        })}}},[_c('md-icon',{staticClass:"text-white md-size-2x"},[_vm._v("admin_panel_settings")]),_c('p',{staticClass:"text-white text-uppercase mt-3 text-base font-bold"},[_vm._v("Live class")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }